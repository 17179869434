input::file-selector-button {
  padding: 5px 12px;
  border-radius: 10px;
  color: #3c6ba5;
  background-color: '#4d6e97';
  cursor: pointer;
  outline: none;
  border: 1px solid #3c6ba5;
  margin: 5px;
  /* width: 1px; */
}

input::file-selector-button:active {
  transform: translateY(2px);
}

input::file-selector-button:hover {
  color: #3c6ba5;
  border: 2px solid #3c6ba5;
  margin: 4px;
}

/* Hide the "Powered by CKEditor" text */
.ck.ck-toolbar__bottom .ck-toolbar__items .ck-toolbar__item:last-child {
  display: none;
}

.dash_sr .MuiSelect-select{
  padding: 5px 15px;
}
.dash_dt {
  color: #fff;
  font-weight: bold;
  
}
.dash_dt input{
  color: #fff !important;
  font-weight: bold;
}

/* .bold-date {
  color: #fff !important;
  font-weight: bold;
} */
/* .css-1ql7g3c-MuiButtonBase-root-MuiIconButton-root {
  color: #fff !important
}
.css-d58xje-MuiInputBase-input-MuiOutlinedInput-input{
  color: #fff !important;
} */
 
/* .css-1c5w2no-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input{
  
} */